import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import * as api from "@dki/api-client";
import { Range } from "@libs/dash/core/entity";
import { HistoryLogsModule, VouchersReportComponent } from "@libs/dash/features/v1";
import { ExportOption, ExportOptionType, LayoutFilterService } from "@libs/dash/features/v2";
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from "@libs/shared/modules/common-components";
import { TranslateService } from "@libs/shared/modules/i18n";
import { map } from "rxjs/operators";

interface PerVoucherInternalData {
	voucher_type: string;
	total: number;
	monday: number;
	tuesday: number;
	wednesday: number;
	thursday: number;
	friday: number;
	saturday: number;
	sunday: number;
}


@Component({
	selector: 'vouchers-report-tab',
	standalone: true,
	imports: [CommonModule, HistoryLogsModule, CommonLayoutTableComponent],
	templateUrl: './vouchers-report-tab.component.html',
	styleUrl: './vouchers-report-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VouchersReportTabComponent extends VouchersReportComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);
	private readonly _translate: TranslateService = inject(TranslateService);
	private readonly _dr: DestroyRef = inject(DestroyRef);

	dataInternalSig = toSignal<{ data: api.WeeklyVouchersReport; i18n: any }>(this.viewData$.pipe(map((data: any) => data)), {
		injector: this._injector,
	});
	dataSig = computed<api.WeeklyVouchersReport>(() => this.dataInternalSig().data);

	tableDataSig = computed<PerVoucherInternalData[]>(() => {
		if (this.dataSig()) {
			return [
				...Object.keys(this.dataSig().by_voucher_type).map((key) => {
					return {
						voucher_type: key,
						...this.dataSig().by_voucher_type[key].by_weekday,
						total: this.dataSig().by_voucher_type[key].total,
					};
				}),
			];
		}
		return [];
	});

	tableConfigSig = computed<CommonTableConfig<PerVoucherInternalData>>(() => {
		if (this.dataSig() && this.dataSig()?.total?.by_weekday) {
			return {
				titleKey: 'vouchers-report.title',
				columns: [
					{
						key: 'voucher_type',
						columnType: ColumnType.Translation,
						alignment: 'left',
						headerLabelKey: `vouchers-report.voucher`,
						totalGetter: () => `vouchers-report.total`,
					},
					...Object.keys(this.dataSig()?.total?.by_weekday).map((key) => {
						return {
							key,
							columnType: ColumnType.Price,
							alignment: 'right',
							headerLabelKey: `vouchers-report.weekdays.${key}`,
							totalGetter: (data: any[]) => data.reduce((acc: number, i: PerVoucherInternalData) => acc + i[key], 0),
						};
					}),
					{
						key: 'total',
						columnType: ColumnType.Price,
						headerLabelKey: `vouchers-report.total`,
						classGetter: () => '!bg-neutral-80',
						alignment: 'right',
						totalGetter: (data: any[]) => data.reduce((acc: number, i: PerVoucherInternalData) => acc + i.total, 0),
					},
				],
			};
		}
		return null;
	});

	override ngOnInit() {
		super.ngOnInit();

		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				const restaurant = this._layoutFilterServiceService?.filters?.restaurant();
				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);
		const availableExportOptions: ExportOption[] = [
			{ label: ExportOptionType.CSV, selected: false, type: ExportOptionType.CSV },
			{ label: ExportOptionType.XLSX, selected: false, type: ExportOptionType.XLSX },
		];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.CSV:
					this.download('csv', this.dataInternalSig())
					break;
				case ExportOptionType.XLSX:
					//this.downloadXlsx();
					break;
				case ExportOptionType.PDF:
					this.download('pdf', this.dataInternalSig());
					break;
			}
		}
	}
}
