import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	effect,
	inject,
	Injector,
	OnInit, Signal,
	signal,
	ViewChild
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { Range } from '@libs/dash/core/entity';
import { SessionsHistoryModule, SessionsReportsComponent } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import { ColumnType, CommonLayoutTableComponent, CommonSelectOption, CommonTableConfig } from '@libs/shared/modules/common-components';
import { TranslateModule, TranslateService } from '@libs/shared/modules/i18n';
import { map } from 'rxjs/operators';
import { FilterDataPipe } from "./filter-data.pipe";

const PER_SESSION_REPORTS_COLUMN = {
	Vendeur: 'Vendeur',
	Manager: 'Manager',
	Number: 'sessionNumber',
	Closing: 'sessionClosing',
	Validation: 'sessionValidation',
	Reglements: 'reglements',
	Theoretical: 'theoretical',
	Declared: 'declared',
	Gap: 'gap',
	Comment: 'comment',
};

@Component({
	selector: 'per-session-tab',
	standalone: true,
	imports: [CommonModule, SessionsHistoryModule, CommonLayoutTableComponent, TranslateModule, MatChipsModule, FilterDataPipe],
	templateUrl: './per-session-tab.component.html',
	styleUrl: './per-session-tab.component.scss',
	providers: [TranslateService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerSessionTabComponent extends SessionsReportsComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _translateService: TranslateService = inject(TranslateService);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	selectedVendors: Signal<string[]> = computed(() =>
		this._layoutFilterServiceService.filters?.vendors ? this._layoutFilterServiceService.filters?.vendors() : null
	);

	recapLineSigTotal = computed(() => {
		return this.dataSig().reduce(
			(acc, i) => {
				Object.keys(acc).forEach((key) => {
					acc[key] = acc[key] + (i.recapLine[0][key] ?? 0);
				});
				return acc;
			},
			{
				cancellation: 0,
				refund: 0,
				discount: 0,
				withdrawn: 0,
			}
		);
	});

	tableConfig: CommonTableConfig<any> = {
		titleKey: 'sessions-history.title',
		detailsKey: 'entriesSource',
		columns: [
			{
				key: PER_SESSION_REPORTS_COLUMN.Vendeur,
				headerLabelKey: 'sessions-history.reports.vendor',
				alignment: 'left',
				columnType: ColumnType.Text,
				detailGetter: () => '',
				valueGetter: (_, row: any) => row.personnelsSource.find((s) => s.label === PER_SESSION_REPORTS_COLUMN.Vendeur).name,
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Manager,
				headerLabelKey: 'sessions-history.reports.manager',
				alignment: 'left',
				columnType: ColumnType.Text,
				detailGetter: () => '',
				valueGetter: (_, row: any) => row.personnelsSource.find((s) => s.label === PER_SESSION_REPORTS_COLUMN.Manager).name,
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Number,
				headerLabelKey: 'sessions-history.reports.sessionsNb',
				alignment: 'right',
				columnType: ColumnType.Text,
				detailGetter: () => '',
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Closing,
				headerLabelKey: 'sessions-history.reports.opening',
				alignment: 'right',
				columnType: ColumnType.Date,
				info: { dateFormat: 'dd/MM/yyy - hh:mm' },
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Validation,
				headerLabelKey: 'sessions-history.reports.closing',
				alignment: 'right',
				columnType: ColumnType.Date,
				info: { dateFormat: 'dd/MM/yyy - hh:mm' },
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Reglements,
				headerLabelKey: 'sessions-history.reports.reglements',
				columnType: ColumnType.Text,
				alignment: 'right',
				totalColumnType: ColumnType.Translation,
				valueGetter: () => '',
				totalGetter: () => 'sessions-history.reports.total',
				detailGetter: (_, row: any) => {
					return this.localeTextSig()?.entries[row?.category] || row.category;
				},
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Theoretical,
				headerLabelKey: 'sessions-history.reports.amountTh',
				columnType: ColumnType.Price,
				alignment: 'right',
				valueGetter: (_, row: any) => this.totalCost(row, 1),
				totalGetter: (data: any[]) => data?.reduce((acc, row) => acc + this.totalCost(row, 1), 0),
				detailGetter: (_, row: any) => row.amount.theoretical,
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Declared,
				headerLabelKey: 'sessions-history.reports.declaredAmount',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => this.totalCost(row, 2),
				totalGetter: (data: any[]) => data?.reduce((acc, row) => acc + this.totalCost(row, 2), 0),
				detailGetter: (_, row: any) => row.amount.real,
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Gap,
				headerLabelKey: 'sessions-history.reports.gap',
				alignment: 'right',
				columnType: ColumnType.Price,
				valueGetter: (_, row: any) => this.totalCost(row, 3),
				totalGetter: (data: any[]) => data?.reduce((acc, row) => acc + this.totalCost(row, 3), 0),
				detailGetter: (_, row: any) => row.amount.theoretical - row.amount.real,
			},
			{
				key: PER_SESSION_REPORTS_COLUMN.Comment,
				headerLabelKey: 'sessions-history.reports.comments',
				alignment: 'left',
				columnType: ColumnType.Text,
				valueGetter: () => '',
				totalGetter: () => '',
			},
		],
	};

	localeTextSig = toSignal<Record<string, any>>(
		this._translateService.selectTranslation('sessions-history').pipe(map((i18n: Record<string, any>) => i18n?.reports)),
		{
			injector: this._injector,
		}
	);

	override ngOnInit(): void {
		super.ngOnInit();

		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				const restaurant = this._layoutFilterServiceService?.filters?.restaurant();
				if (range && restaurant) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const texts = this.localeTextSig();
				if (texts) {
					this.localeText = texts;
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const data = this.dataSig();
				if (data) {
					let options = [];
					data.forEach((item: any) => {
						if (!options.some((i) => i.id === item.personnelsSource.find((p) => p.label === 'Vendeur').id)) {
							options.push(item.personnelsSource.find((p) => p.label === 'Vendeur'));
						}
					});
					this._layoutFilterServiceService.registerFilterOptions(
						'vendors',
						signal(
							options.map(
								(i: any) =>
									({
										value: i.id.toString(),
										label: i.name,
									}) as CommonSelectOption
							)
						)
					);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const kiosks = this._layoutFilterServiceService?.filters?.kiosks();
				this.formGroup.get('toggle').setValue(kiosks ?? false);
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				const data = this.recapLineSigTotal();
				if (data) {
					this._layoutFilterServiceService.registerFilterOptions('kiosks', signal(this.kioskSessionsCount));
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF }];

		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.CSV:
					this.downloadAll();
					break;
			}
		}
	}
}
