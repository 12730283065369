import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Injector, OnInit, Signal, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Range } from '@libs/dash/core/entity';
import { ProductsReportTileComponent, ReportsModule } from '@libs/dash/features/v1';
import { ExportOption, ExportOptionType, LayoutFilterService } from '@libs/dash/features/v2';
import {
	ColumnType,
	CommonLayoutTableComponent,
	CommonTableColumnConfig,
	CommonTableColumnGroup, CommonTableColumnSecondaryGroup,
	CommonTableConfig
} from '@libs/shared/modules/common-components';
import { ProductReportsDataConfigFilterPipe } from './product-reports-data-config-filter.pipe';
import { ProductReportsDataFilterPipe } from './product-reports-data-filter.pipe';
import { ProductReportsTableGroupsFilterPipe } from './product-reports-table-groups-filter.pipe';
import { ProductReportsTableGroupsSecondaryFilterPipe } from "./product-reports-table-groups-secondary-filter.pipe";

@Component({
	selector: 'product-reports-tab',
	standalone: true,
	imports: [
		CommonModule,
		CommonLayoutTableComponent,
		ReportsModule,
		ProductReportsDataFilterPipe,
		ProductReportsDataConfigFilterPipe,
		ProductReportsTableGroupsFilterPipe,
		ProductReportsTableGroupsFilterPipe,
		ProductReportsTableGroupsSecondaryFilterPipe
	],
	templateUrl: './product-reports-tab.component.html',
	styleUrl: './product-reports-tab.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductReportsTabComponent extends ProductsReportTileComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _layoutFilterServiceService: LayoutFilterService = inject(LayoutFilterService);

	dataSig = toSignal<{ productsReport: any[]; i18n: any }>(this.viewData$, { injector: this._injector });
	tableDataSig = computed<any[]>(() => this.dataSig()?.productsReport || []);
	selectedChannels: Signal<string[]> = computed(() =>
		this._layoutFilterServiceService.filters?.channels ? this._layoutFilterServiceService.filters?.channels() : null
	);

	tableGroupsSig = computed<CommonTableColumnGroup[]>(() => {
		if (this.dataSig()) {
			const groups = [
				{
					titleKey: 'productsReportTile.total',
					columns: ['total-menu', 'total-solo'],
					columnsMain: ['total-menu-qt', 'total-menu-val', 'total-solo-qt', 'total-solo-val'],
				},
			];

			if (this.dataSig()?.productsReport && this.dataSig().productsReport.length > 0) {
				groups.push(
					...Object.keys(this.dataSig().productsReport[0].channels).map((column) => {
						return {
							titleKey: 'channels.' + [column],
							columns: [column + '__menu', column + '__solo'],
							columnsMain: [column + '__menu-qt', column + '__menu-val', column + '__solo-qt', column + '__solo-val'],
						};
					})
				);
			}

			return groups;
		}
		return null;
	});

	tableSecondaryGroupsSig = computed<CommonTableColumnSecondaryGroup[]>(() => {
		if (this.dataSig()) {
			const groups = [
				{
					titleKey: 'productsReportTile.product',
					columns: ['productName'],
					key: 'productNameGroup',
					colspan: 1,
				},
				{
					titleKey: 'productsReportTile.inMenu',
					columns: ['total-menu-qt', 'total-menu-val'],
					key: 'total-menu',
					colspan: 2,
				},
				{
					titleKey: 'productsReportTile.solo',
					columns: ['total-solo-qt', 'total-solo-val'],
					key: 'total-solo',
					colspan: 2,
				},
			];

			if (this.dataSig()?.productsReport && this.dataSig().productsReport.length > 0) {
				groups.push(
					...Object.keys(this.dataSig().productsReport[0].channels)
						.map((column) => {
							return [
								{
									titleKey: 'productsReportTile.inMenu',
									key: column + '__menu',
									columns: [column + '__menu-qt', column + '__menu-val'],
									colspan: 2,
								},
								{
									titleKey: 'productsReportTile.solo',
									key: column + '__solo',
									columns: [column + '__solo-qt', column + '__solo-val'],
									colspan: 2,
								},
							];
						})
						.flat()
				);
			}

			return groups;
		}
		return null;
	});

	tableConfigSig = computed<CommonTableConfig<any>>(() => {
		if (!!this.dataSig()) {
			const config: CommonTableConfig<any> = {
				titleKey: 'reports.productsReportTableTitle',
				scrollable: true,
				columns: [
					{
						key: 'productName',
						columnType: ColumnType.Text,
						headerLabelKey: 'productsReportTile.name',
						width: '100px',
						alignment: 'left',
					},
					{
						key: 'total-menu-qt',
						columnType: ColumnType.Text,
						headerLabelKey: 'productsReportTile.count',
						width: '100px',
						alignment: 'right',
						valueGetter: (cell: unknown, row: any) => row?.total?.inMenu?.count,
						totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.total?.inMenu?.count, 0),
						classGetter: () => '!bg-neutral-80',
					},
					{
						key: 'total-menu-val',
						columnType: ColumnType.Price,
						headerLabelKey: 'productsReportTile.value',
						width: '100px',
						alignment: 'right',
						valueGetter: (cell: unknown, row: any) => row?.total?.inMenu?.value,
						totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.total?.inMenu?.value, 0),
						classGetter: () => '!bg-neutral-80',
					},
					{
						key: 'total-solo-qt',
						columnType: ColumnType.Text,
						headerLabelKey: 'productsReportTile.count',
						width: '100px',
						alignment: 'right',
						valueGetter: (cell: unknown, row: any) => row?.total?.solo?.count,
						totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.total?.solo?.count, 0),
					},
					{
						key: 'total-solo-val',
						columnType: ColumnType.Price,
						headerLabelKey: 'productsReportTile.value',
						width: '100px',
						alignment: 'right',
						valueGetter: (cell: unknown, row: any) => row?.total?.solo?.value,
						totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.total?.solo?.value, 0),
					},
				],
			};

			if (this.dataSig()?.productsReport && this.dataSig()?.productsReport.length > 0) {
				config.columns = [
					...config.columns,
					...Object.keys(this.dataSig()?.productsReport[0]?.channels)?.reduce((acc: CommonTableColumnConfig<any>[], column, index) => {
						acc.push(
							{
								key: column + '__menu-qt',
								columnType: ColumnType.Text,
								headerLabelKey: 'productsReportTile.count',
								width: '100px',
								alignment: 'right',
								valueGetter: (cell: unknown, row: any) => row?.channels[column]?.inMenu?.count,
								totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.channels[column]?.inMenu?.count, 0),
								classGetter: () => '!bg-neutral-80',
							},
							{
								key: column + '__menu-val',
								columnType: ColumnType.Price,
								headerLabelKey: 'productsReportTile.value',
								width: '100px',
								alignment: 'right',
								valueGetter: (cell: unknown, row: any) => row?.channels[column]?.inMenu?.value,
								totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.channels[column]?.inMenu?.value, 0),
								classGetter: () => '!bg-neutral-80',
							},
							{
								key: column + '__solo-qt',
								columnType: ColumnType.Text,
								headerLabelKey: 'productsReportTile.count',
								width: '100px',
								alignment: 'right',
								valueGetter: (cell: unknown, row: any) => row?.channels[column]?.solo?.count,
								totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.channels[column]?.solo?.count, 0),
							},
							{
								key: column + '__solo-val',
								columnType: ColumnType.Price,
								headerLabelKey: 'productsReportTile.value',
								width: '100px',
								alignment: 'right',
								valueGetter: (cell: unknown, row: any) => row?.channels[column]?.solo?.value,
								totalGetter: (data: any[]) => data.reduce((acc, row) => acc + row.channels[column]?.solo?.value, 0),
							}
						);
						return acc;
					}, []),
				];
			}
			return config;
		}
		return null;
	});

	override ngOnInit(): void {
		super.ngOnInit();
		this._layoutFilterServiceService.registerFilterOptions('channels', signal([]));
		effect(
			() => {
				const range = this._layoutFilterServiceService?.range();
				if (range) {
					this.dateRange.setValue({ from: range.from.toJSDate(), to: range.to.toJSDate() });
					this.setPeriod(Range.Period);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		effect(
			() => {
				if (this.tableConfigSig() && this.dataSig()?.productsReport && this.dataSig()?.productsReport.length > 0) {
					this._layoutFilterServiceService.registerFilterOptions(
						'channels',
						signal(
							Object.keys(this.dataSig().productsReport[0].channels).map((key) => {
								return {
									value: key,
									labelKey: 'channels.' + key,
								};
							})
						)
					);
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);

		const availableExportOptions: ExportOption[] = [{ label: ExportOptionType.PDF, selected: false, type: ExportOptionType.PDF }];
		this._layoutFilterServiceService.setAvailableExportOptions(availableExportOptions);

		this._layoutFilterServiceService.export
			.pipe(takeUntilDestroyed(this._dr))
			.subscribe((selectedOptions: string[]) => this._exportData(selectedOptions));
	}

	private _exportData(selectedOptions: string[]) {
		if (selectedOptions.length === 0) {
			return;
		}
		for (const option of selectedOptions) {
			switch (option) {
				case ExportOptionType.PDF:
					this.downloadPDF();
					break;
			}
		}
	}
}
