import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getLocale } from '@libs/dash/core';

import { Range } from '@libs/dash/core/entity';
import { TranslateService } from '@libs/shared/modules/i18n';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HISTORY_LOGS_FACADE } from '../../facade/history-logs-facade.injection.token';
import { HistoryLogsServiceProvider } from '../../facade/history-logs-facade.provider.interface';

@Component({
	selector: 'dk-receipts-synth',
	templateUrl: './receipts-synth.component.html',
	styleUrls: ['./receipts-synth.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiptsSynthComponent implements OnDestroy {
	dateRange = new FormGroup({
		from: new FormControl(this._historylogService.range.from.toJSDate()),
		to: new FormControl(this._historylogService.range.to.toJSDate()),
	});

	sub: Subscription;

	prevData = [];
	data = [];
	i18n;
	totalLine: any = {};
	column = '';
	sortActive = false;
	reverse = true;

	filterChanged = new BehaviorSubject<boolean>(false);

	declaredFilterActive = false;
	declaredFilterValue: number;
	declaredValueOperator: string;
	hiddenByDeclaredFilter = [];

	realFilterActive = false;
	realFilterValue: number;
	realValueOperator: string;

	cancelsFilterActive = false;
	cancelsFilterValue: number;
	cancelsValueOperator: string;

	correctionsFilterActive = false;
	correctionsFilterValue: number;
	correctionsValueOperator: string;

	withdrawnFilterActive = false;
	withdrawnFilterValue: number;
	withdrawnValueOperator: string;

	employees = [];
	selectedEmployees = [];

	isFetching$ = this._historylogService.isFetchingSessions$;
	isLoading$ = this._historylogService.isLoading$;

	sessionsSynth$ = this._historylogService.sessionsSynth$;
	localisedTexts$ = this._translateService.selectTranslation('receipts-synth');
	viewData$ = combineLatest([this.localisedTexts$, this.sessionsSynth$, this.filterChanged]).pipe(
		map(([i18n, data]) => {
			if (this.prevData !== data) {
				// new data, need to reset empl. list and selected empl.
				this.employees = [];
				this.selectedEmployees = [];
				data.forEach((entry) => this.employees.push(entry.cashier));
			}
			const filteredData = cloneDeep(data);
			// only executed when sorting
			if (this.declaredFilterActive) {
				filteredData.forEach(
					(entry) => (entry.hidden ||= this.evaluate(this.declaredFilterValue, entry.revenue.declared, this.declaredValueOperator))
				);
			}
			if (this.realFilterActive) {
				filteredData.forEach((entry) => (entry.hidden ||= this.evaluate(this.realFilterValue, entry.revenue.real, this.realValueOperator)));
			}
			if (this.cancelsFilterActive) {
				filteredData.forEach(
					(entry) => (entry.hidden ||= this.evaluate(this.cancelsFilterValue, entry.cancels.amount, this.cancelsValueOperator))
				);
			}
			// currently using refund instead of cancellation since all cancellations are zero values
			if (this.correctionsFilterActive) {
				filteredData.forEach(
					(entry) => (entry.hidden ||= this.evaluate(this.correctionsFilterValue, entry.refund, this.correctionsValueOperator))
				);
			}
			if (this.withdrawnFilterActive) {
				filteredData.forEach(
					(entry) => (entry.hidden ||= this.evaluate(this.withdrawnFilterValue, entry.withdrawn, this.withdrawnValueOperator))
				);
			}
			this.prevData = data;
			this.data = filteredData.sort((a, b) => this.sort(a, b));
			this.i18n = i18n;
			const total = this.computeTotal(filteredData);
			return { i18n, data: this.data, total };
		})
	);

	constructor(
		private _translateService: TranslateService,
		@Inject(HISTORY_LOGS_FACADE) protected readonly _historylogService: HistoryLogsServiceProvider,
		private currencyPipe: CurrencyPipe
	) {
		this.sub = this._historylogService.dateRange$.subscribe((v) => {
			this.dateRange.setValue({ from: v.from.toJSDate(), to: v.to.toJSDate() });
		});
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	sort(a, b) {
		if (this.sortActive) {
			let x = a;
			let y = b;
			const keyPath = this.column;
			const keys = keyPath.split('.');
			for (const key of keys) {
				x = x[key];
				y = y[key];
			}
			return (x < y ? -1 : x > y ? 1 : 0) * (this.reverse ? -1 : 1);
		} else {
			return 0;
		}
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this._historylogService.getSessionSynthesisReport(from, to);
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	download(ext: string) {
		if (ext === 'pdf') {
			const source = document.getElementById('synth-sessions');
			const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true, format: 'letter' });
			doc.html(source, {
				html2canvas: {
					scale: 0.2,
					letterRendering: true,
					ignoreElements: (e) => e.nodeName.includes('MAT'),
					logging: true,
				},
				margin: [30, 5, 5, 5],
				windowWidth: 1000,
				width: 1000,
				fontFaces: [
					{
						family: 'Roboto',
						src: [
							{
								url: '/assets/fonts/roboto.ttf',
								format: 'truetype',
							},
						],
					},
				],
				callback: (doc) => {
					doc.setFontSize(16);
					doc.setFont(undefined, 'bold');
					const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
						to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
					const date = from === to ? from : `${from} - ${to}`;
					doc.setFontSize(12);
					doc.text(`${date}`, 20, 20);
					doc.save(
						`${this.i18n.title}  ${DateTime.fromJSDate(this.dateRange.value.from).toISODate()}_${DateTime.fromJSDate(this.dateRange.value.to).toISODate()}`
					);
				},
			});
		} else {
			const csv = [];
			const pipeOption = ['EUR', 'symbol', '1.2-2'];
			const currency = (v) => this.currencyPipe.transform(v, ...pipeOption);
			this.data.forEach((e) => {
				const entry = {
					[this.i18n.restaurant]: e.restaurant,
					[this.i18n.cashier]: e.cashier,
					[this.i18n.nb_session]: e.sessionCount,
					[`${this.i18n.revenue}_${this.i18n.amount}`]: currency(e.revenue.declared),
					[`${this.i18n.revenue}_${this.i18n.reductions}`]: currency(e.revenue.discount),
					[`${this.i18n.revenue}_%`]: (e.revenue.percent * 100).toFixed(2) + '%',
					[this.i18n.real_revenue]: currency(e.revenue.real),
					[this.i18n.gap]: currency(e.revenue.gap),
					[`${this.i18n.cancels}_${this.i18n.amount}`]: currency(e.cancels.amount),
					[this.i18n.cancels]: e.cancels.count,
					[`${this.i18n.cancels}_%`]: (e.cancels.percent * 100).toFixed(2) + '%',
					[`${this.i18n.corrections}_${this.i18n.amount}`]: currency(e.corrections.amount),
					[this.i18n.corrections]: e.corrections.count,
					[`${this.i18n.corrections}_%`]: (e.corrections.percent * 100).toFixed(2) + '%',
					[this.i18n.refunds]: currency(e.refund),
					[this.i18n.withdrawn]: currency(e.withdrawn),
				};
				csv.push(entry);
			});
			csv.push({
				[this.i18n.restaurant]: this.i18n.total,
				[this.i18n.cashier]: '',
				[this.i18n.nb_session]: this.totalLine.sessionCount,
				[`${this.i18n.revenue}_${this.i18n.amount}`]: currency(this.totalLine.revenue.declared),
				[`${this.i18n.revenue}_${this.i18n.reductions}`]: currency(this.totalLine.revenue.discount),
				[`${this.i18n.revenue}_%`]: (this.totalLine.revenue.percent * 100).toFixed(2) + '%',
				[this.i18n.real_revenue]: currency(this.totalLine.revenue.real),
				[this.i18n.gap]: currency(this.totalLine.revenue.gap),
				[`${this.i18n.cancels}_${this.i18n.amount}`]: currency(this.totalLine.cancels.amount),
				[this.i18n.cancels]: this.totalLine.cancels.count,
				[`${this.i18n.cancels}_%`]: (this.totalLine.cancels.percent * 100).toFixed(2) + '%',
				[`${this.i18n.corrections}_${this.i18n.amount}`]: currency(this.totalLine.corrections.amount),
				[this.i18n.corrections]: this.totalLine.corrections.count,
				[`${this.i18n.corrections}_%`]: (this.totalLine.corrections.percent * 100).toFixed(2) + '%',
				[this.i18n.refunds]: currency(this.totalLine.refund),
				[this.i18n.withdrawn]: currency(this.totalLine.withdrawn),
			});
			const options = {
				filename: `${this.i18n.title}  ${DateTime.fromJSDate(this.dateRange.value.from).toISODate()}_${DateTime.fromJSDate(this.dateRange.value.to).toISODate()}`,
				decimalSeparator: '.',
				fieldSeparator: ';',
				showLabels: true,
				showTitle: true,
				title: `${this.i18n.title}  ${DateTime.fromJSDate(this.dateRange.value.from).toISODate()}_${DateTime.fromJSDate(this.dateRange.value.to).toISODate()}`,
				useKeysAsHeaders: true,
			};
			const csvExporter = new ExportToCsv(options);
			if (csv.length > 0) {
				csvExporter.generateCsv(csv);
			}
		}
	}

	filteredEmployee(row) {
		return this.selectedEmployees.length === 0 ? false : !this.selectedEmployees.includes(row.cashier);
	}

	resetEmplFilter() {
		this.selectedEmployees = [];
	}

	onEmplChanges(eventValue) {
		this.selectedEmployees = eventValue;
		this.filterChanged.next(true);
	}

	onFilterChange() {
		this.filterChanged.next(true);
	}

	evaluate(param1, param2, operator) {
		if (operator === 'lt') {
			return param2 > param1;
		} else if (operator === 'gt') {
			return param2 < param1;
		} else if (operator === 'eq') {
			return param1 != param2;
		} else {
			// invalid
			return null;
		}
	}

	setSorting(colPath, reverse = true) {
		if (this.column === colPath) {
			this.sortActive = false;
			this.column = '';
		} else {
			this.column = colPath;
			this.sortActive = true;
		}
		this.reverse = reverse;
		this.filterChanged.next(true);
	}

	computeTotal(data) {
		const total = data.reduce(
			(acc, item) => {
				const { cashier, restaurant, hidden, ...rest } = item;
				if (this.selectedEmployees.length > 0 && this.selectedEmployees.indexOf(item.cashier) < 0) {
					return acc;
				}
				if (item.hidden) {
					return acc;
				}
				for (const key in rest) {
					if (typeof rest[key] === 'object') {
						for (const subKey in rest[key]) {
							// nested keys
							acc[key][subKey] += rest[key][subKey];
						}
					} else {
						acc[key] += rest[key];
					}
				}

				return acc;
			},
			{
				sessionCount: 0,
				revenue: {
					declared: 0,
					real: 0,
					discount: 0,
					gap: 0,
					percent: 0,
				},
				cancels: {
					amount: 0,
					percent: 0,
					count: 0,
				},
				refund: 0,
				withdrawn: 0,
				corrections: {
					amount: 0,
					percent: 0,
					count: 0,
				},
			}
		);
		total.revenue.percent = total.revenue.discount / total.revenue.declared;
		total.cancels.percent = total.cancels.amount / total.revenue.declared;
		this.totalLine = total;
		return total;
	}
}