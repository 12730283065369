import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Injector, Input, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { DELAY_200 } from '@bk/frontend-common';
import { Restaurant } from '@dki/api-client';
import { RestaurantSelectComponent, RestaurantSelectionModule } from '@libs/dash/features/v1';
import { CommonLayoutSelectComponent, CommonSelectOption } from '@libs/shared/modules/common-components';
import { debounceTime, take } from 'rxjs/operators';

@Component({
	selector: 'dk-restaurant-selector-new',
	standalone: true,
	imports: [
		CommonModule,
		RestaurantSelectionModule,
		MatFormField,
		MatFormFieldModule,
		MatIcon,
		MatLabel,
		MatOption,
		MatSelect,
		ReactiveFormsModule,
		FormsModule,
		MatButton,
		CommonLayoutSelectComponent,
	],
	templateUrl: './restaurant-selector-new.component.html',
	styleUrl: './restaurant-selector-new.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantSelectorNewComponent extends RestaurantSelectComponent implements OnInit {
	private readonly _injector: Injector = inject(Injector);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private _restaurantsListSig: Signal<Restaurant[]> = toSignal(this.restaurantList$, { injector: this._injector });

	restaurantsOptionsSig: Signal<CommonSelectOption[]> = computed(() => {
		return this._restaurantsListSig()?.map((r) => ({ value: r.id, label: r.name })) ?? [];
	});

	get selectedValue(): string | string[] {
		return !this.multiSelectionEnabled ? this.selectedRestaurant?.id ?? null : this.multiSelectedRestaurants?.map((r) => r?.id) ?? [];
	}

	@Input() set multiple(multiple: boolean) {
		this.restaurantService.enableMultiRestaurants(multiple ?? false);
	}

	override ngOnInit() {
		super.ngOnInit();

		this.restaurantChanged$.pipe(debounceTime(DELAY_200), take(1)).subscribe(() => {
			this.onRestaurantSelect(
				!this.multiSelectionEnabled ? this.selectedRestaurant?.id ?? null : this.multiSelectedRestaurants?.map((r) => r?.id) ?? []
			);
		});
	}

	onRestaurantSelect(restaurantIds: string | string[]) {
		if (Array.isArray(restaurantIds)) {
			this.multiSelectRestaurants({ value: restaurantIds.map((id) => this._restaurantsListSig().find((r) => r.id === id)) });
			return;
		}

		this.selectRestaurant({ value: this._restaurantsListSig().find((r) => r.id === restaurantIds) });
	}
}
